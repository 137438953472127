*{
    font-family: "Cabin" ,"Libre Baskerville", serif;
}

@media only screen and (min-width : 990px){
    /* Styles pour cette Media Queries */
    .contactForm{
        margin-inline: 40vh !important;
    }
    #videoYt{
        width: 560px !important;
        height: 315px !important;
    }
    #entreprisesTitle{
        font-size: 4em !important;
    }
    #parcoursTitle{
        font-size: 4em !important;
    }
    #press_card{
        height: 20vh;
    }
    }

h1, h2{
    
    font-family: "Libre Baskerville", serif;
}
/*background*/
.bg_img{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    background-image: url(../../assets/img/bg.png) ;
    opacity: 0.7;
}

.over_background_title {
    margin-top: 15vh !important;
    color: #ffffff;
    font-size: 10vw;
    opacity: 1;
}
.over_background {
    color: #ffffff;
    font-size: 5vw;
}

/*navbar*/

.dropdown {
    position: relative;
    display: inline-block;
  }

.entreprises {
    display: none;
}

.entreprises_page:active .entreprises {
    display: block;
}

  /* Dropdown Content (Hidden by Default) */
.dropDownContent {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
.dropDownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
.dropDownContent a:hover {color: #36E2AD;}

  /* Show the dropdown menu on hover */
.dropDown:hover .dropDownContent {display: block;}
.dropDown a:visited .dropDownContent{display: none;}


.nav_link:hover{
    color: #ff98c3 !important;
}

.navbar a.active {
  color: #ff98c3;
}

.navbar a.active:after {
    width: 90%;
    background: #ff98c3;
}
.navbar a.active:hover:after {
    width: 90%;
    background: #ff98c3;
}

.nav_link {
    color: black;
    text-decoration:none;
    line-height: 70px;
    text-align: center;
}
.nav_link:after {
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}
.nav_link:hover:after {
    width: 90%;
    background: #ff98c3;
}

.navbar {
    background-color: #ffffff;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 1;
    transition: top 0.3s;
  }

  .navbar.hidden {
    top: -90px; /*hauteur de la navbar */
  }

  .navbar.visible {
    top: 0;
  }

.logo{
    width: 70px;
    margin-top: 20px;
}

/* Page formations */

.bgParcours{
    background-image: url("../img/parcours.png");
    height: 350px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.7;
}

.formations{
    display: flex;
    justify-content: space-around;
    background-color: #eafbf69d;
}

/*modules*/

#specialisation{
    background-color: #9ec3fe;
    color: white;
    height: 100%;
}
#specialisation:hover{
    background-color: #5596ff;
    color: white;
}
#commun{
    background-color: #ffbcd8;
    color: white;
    height: 100%;
}
#commun:hover{
    background-color: #fd92be;
    color: white;
}

#stage{
    background-color: #a7e872;
    color: white;
    height: 100%;
}
#stage:hover{
    background-color: #84e038;
}

.btn-close{
    margin: 8px !important;
}
.btn_modal{
    padding: 0;
    border: none;
}
.btn_modal:hover{
    padding: 0;
}

/*page Agenda*/



/*page entreprises*/
.bgEntreprises{
    background-image: url("../img/entreprises.png");
    height: 350px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.7;
}

/*Page presse*/
.bgAgenda{
    background-image: url("../img/agenda.png");
    height: 350px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.7;
}


.bgPresse{
    background-image: url("../img/bgPresse.jpeg");
    height: 350px;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.7;
}

#press_img {
    height: 15vh;
    width: auto;
}

#press_card {
    font-weight: bold;
}


#press_btn {
    background-color: #0cd196;
    border: none !important;
}

#press_btn:hover {
    background-color: #78fad3;
}

/* .pagination_btn btn {
    color: red !important;
} */

/*contact form*/



    /*slider*/
    .slider {
        margin:0 20px;
        overflow:"hidden";
        padding:2rem 0;
      }
      

    .carousel-img, .slider img{
          height: 200px;
      width: auto;
      /* border-radius: 10px; */
      /* margin: 10px; */

      }
      
      /* .react-multi-carousel-list {
      padding: 0rem 0 2rem 0;
      }
      
      .custom-dot-list-style button{
      border: none;
      background: rgb(255, 68, 68);
      } 
      .react-multi-carousel-dot.react-multi-carousel-dot--active button{
      background: rgb(255, 68, 68) !important;
      }  */

      /* video YT*/

 

    